.loader {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    border-radius: 50px;
    border: 6px solid rgba(150, 150, 150, .5);

    &:after {
        content: "";
        position: absolute;
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        border-radius: 50px;
        border: 6px solid transparent;
        border-top-color: $brand-primary;
        -webkit-animation: adjust-hue 1s linear infinite;
        animation: adjust-hue 1s linear infinite;
    }
}

@-webkit-keyframes adjust-hue {
    0% {
        -webkit-transform: rotate(0deg);
        tranform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        tranform: rotate(360deg);
    }
}

@keyframes adjust-hue {
    0% {
        -webkit-transform: rotate(0deg);
        tranform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        tranform: rotate(360deg);
    }
}
