.tariffOverview {
    &.table {
        > tbody {
            > tr {
                &.bg-primary {
                    th,
                    td {
                        background-color: $brand-primary;
                    }
                }
            }
        }
    }

    td {
        &.device {
            .price {
                &-monthly {
                    color: $brand-primary;
                    font-size: ($font-size-large * 1.2);
                    font-weight: bold;
                }
            }
        }
    }
}
