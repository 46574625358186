
//list
ul {
    &.list-square {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            padding-left: 18px;
            position: relative;
            font-size:$font-size-base * 1.2 ;

            &:before {
                content: "\f00c";
                color: $brand-success;
                position: absolute;
                left: 0;
                font-weight: 900;
                font-family: $font-awesome;
                font-size: $font-size-base *1;
            }
        }
    }
}
