.summary {

    h2 {
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        padding: 10px 0;
    }

    dl {
        @extend .row;
    }

    dt {
        @include make-xs-column(3);
    }

    dd {
        @include make-xs-column(9);
        margin-bottom: 10px;
    }
}
