.accordion {

    .panel-heading p:last-of-type {
        margin: 0;
    }

    table {
        width: 100% !important;
        font-size: 14px;
        border-collapse: separate;
        border-spacing: 0;
        border: 0;
    }

    th, td {
        border: 0;
        padding: 5px 10px;
    }

    td {
        background: #ccc;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        text-align: center;
        vertical-align: middle;

        &:first-child {
            background: #ededed;
            text-align: left;
        }
    }

    th, td.group-heading {
        background: #E50000;
        color: #fff;
        border-right: 2px solid #fff;
        font-weight: bold;
    }
}





