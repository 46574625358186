.img {
    &-filter {
        &-grayscale {
            filter: grayscale(100%);
            transition: filter .2s ease-in-out;

            &:hover {
                filter: grayscale(0);
            }
        }
    }
}
