.slick-slide-navigation {
    .slick {
        &-list {
            margin: 0 40px;
        }

        &-slide {
            cursor: pointer;
            margin: 10px 5px;
            border: 1px solid #eee;

            &.slick-current {
                border-color: $red;
            }
        }

        &-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            cursor: pointer;
            font-size: 20px;
            color: #333;

            &-prev {
                left: 0;
            }

            &-next {
                right: 0;
            }

        }
    }
}
