.optionsForm {
    background: #ebebeb;
    padding: 5px 20px;
    margin: 20px 0;

    form {
        width: 50%;

        .option_row {
            position: relative;
            border-bottom: 1px solid $gray;

            .radio, .checkbox {
                margin: 5px 0;
            }

            .price {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
