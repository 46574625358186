/** MARGIN
-------------------------------------------------------------------------------- */

.m {

    /** TOP
    ------------------------------ */
    &t {
        &-0 { margin-top:  0   !important; }
        &-1 { margin-top: 10px !important; }
        &-2 { margin-top: 20px !important; }
        &-3 { margin-top: 30px !important; }
        &-4 { margin-top: 40px !important; }
        &-5 { margin-top: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { margin-top:  0   !important; }
                &-1 { margin-top: 10px !important; }
                &-2 { margin-top: 20px !important; }
                &-3 { margin-top: 30px !important; }
                &-4 { margin-top: 40px !important; }
                &-5 { margin-top: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { margin-top:  0   !important; }
                &-1 { margin-top: 10px !important; }
                &-2 { margin-top: 20px !important; }
                &-3 { margin-top: 30px !important; }
                &-4 { margin-top: 40px !important; }
                &-5 { margin-top: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { margin-top:  0   !important; }
                &-1 { margin-top: 10px !important; }
                &-2 { margin-top: 20px !important; }
                &-3 { margin-top: 30px !important; }
                &-4 { margin-top: 40px !important; }
                &-5 { margin-top: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { margin-top:  0   !important; }
                &-1 { margin-top: 10px !important; }
                &-2 { margin-top: 20px !important; }
                &-3 { margin-top: 30px !important; }
                &-4 { margin-top: 40px !important; }
                &-5 { margin-top: 50px !important; }
            }
        }
    }

    /** BOTTOM
    ------------------------------ */
    &b {
        &-0 { margin-bottom:  0   !important; }
        &-1 { margin-bottom: 10px !important; }
        &-2 { margin-bottom: 20px !important; }
        &-3 { margin-bottom: 30px !important; }
        &-4 { margin-bottom: 40px !important; }
        &-5 { margin-bottom: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { margin-bottom:  0   !important; }
                &-1 { margin-bottom: 10px !important; }
                &-2 { margin-bottom: 20px !important; }
                &-3 { margin-bottom: 30px !important; }
                &-4 { margin-bottom: 40px !important; }
                &-5 { margin-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { margin-bottom:  0   !important; }
                &-1 { margin-bottom: 10px !important; }
                &-2 { margin-bottom: 20px !important; }
                &-3 { margin-bottom: 30px !important; }
                &-4 { margin-bottom: 40px !important; }
                &-5 { margin-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { margin-bottom:  0   !important; }
                &-1 { margin-bottom: 10px !important; }
                &-2 { margin-bottom: 20px !important; }
                &-3 { margin-bottom: 30px !important; }
                &-4 { margin-bottom: 40px !important; }
                &-5 { margin-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { margin-bottom:  0   !important; }
                &-1 { margin-bottom: 10px !important; }
                &-2 { margin-bottom: 20px !important; }
                &-3 { margin-bottom: 30px !important; }
                &-4 { margin-bottom: 40px !important; }
                &-5 { margin-bottom: 50px !important; }
            }
        }
    }

    /** LEFT
    ------------------------------ */
    &l {
        &-0 { margin-left:  0   !important; }
        &-1 { margin-left: 10px !important; }
        &-2 { margin-left: 20px !important; }
        &-3 { margin-left: 30px !important; }
        &-4 { margin-left: 40px !important; }
        &-5 { margin-left: 50px !important; }

        &-auto { margin-left: auto !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { margin-left:  0   !important; }
                &-1 { margin-left: 10px !important; }
                &-2 { margin-left: 20px !important; }
                &-3 { margin-left: 30px !important; }
                &-4 { margin-left: 40px !important; }
                &-5 { margin-left: 50px !important; }

                &-auto { margin-left: auto !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { margin-left:  0   !important; }
                &-1 { margin-left: 10px !important; }
                &-2 { margin-left: 20px !important; }
                &-3 { margin-left: 30px !important; }
                &-4 { margin-left: 40px !important; }
                &-5 { margin-left: 50px !important; }

                &-auto { margin-left: auto !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { margin-left:  0   !important; }
                &-1 { margin-left: 10px !important; }
                &-2 { margin-left: 20px !important; }
                &-3 { margin-left: 30px !important; }
                &-4 { margin-left: 40px !important; }
                &-5 { margin-left: 50px !important; }

                &-auto { margin-left: auto !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { margin-left:  0   !important; }
                &-1 { margin-left: 10px !important; }
                &-2 { margin-left: 20px !important; }
                &-3 { margin-left: 30px !important; }
                &-4 { margin-left: 40px !important; }
                &-5 { margin-left: 50px !important; }

                &-auto { margin-left: auto !important; }
            }
        }
    }

    /** RIGHT
    ------------------------------ */
    &r {
        &-0 { margin-right:  0   !important; }
        &-1 { margin-right: 10px !important; }
        &-2 { margin-right: 20px !important; }
        &-3 { margin-right: 30px !important; }
        &-4 { margin-right: 40px !important; }
        &-5 { margin-right: 50px !important; }

        &-auto { margin-right: auto !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { margin-right:  0   !important; }
                &-1 { margin-right: 10px !important; }
                &-2 { margin-right: 20px !important; }
                &-3 { margin-right: 30px !important; }
                &-4 { margin-right: 40px !important; }
                &-5 { margin-right: 50px !important; }

                &-auto { margin-right: auto !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { margin-right:  0   !important; }
                &-1 { margin-right: 10px !important; }
                &-2 { margin-right: 20px !important; }
                &-3 { margin-right: 30px !important; }
                &-4 { margin-right: 40px !important; }
                &-5 { margin-right: 50px !important; }

                &-auto { margin-right: auto !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { margin-right:  0   !important; }
                &-1 { margin-right: 10px !important; }
                &-2 { margin-right: 20px !important; }
                &-3 { margin-right: 30px !important; }
                &-4 { margin-right: 40px !important; }
                &-5 { margin-right: 50px !important; }

                &-auto { margin-right: auto !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { margin-right:  0   !important; }
                &-1 { margin-right: 10px !important; }
                &-2 { margin-right: 20px !important; }
                &-3 { margin-right: 30px !important; }
                &-4 { margin-right: 40px !important; }
                &-5 { margin-right: 50px !important; }

                &-auto { margin-right: auto !important; }
            }
        }
    }

    /** TOP & BOTTOM
    ------------------------------ */
    &y{
        &-0 { margin-top:  0   !important; margin-bottom:  0   !important; }
        &-1 { margin-top: 10px !important; margin-bottom: 10px !important; }
        &-2 { margin-top: 20px !important; margin-bottom: 20px !important; }
        &-3 { margin-top: 30px !important; margin-bottom: 30px !important; }
        &-4 { margin-top: 40px !important; margin-bottom: 40px !important; }
        &-5 { margin-top: 50px !important; margin-bottom: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { margin-top:  0   !important; margin-bottom:  0   !important; }
                &-1 { margin-top: 10px !important; margin-bottom: 10px !important; }
                &-2 { margin-top: 20px !important; margin-bottom: 20px !important; }
                &-3 { margin-top: 30px !important; margin-bottom: 30px !important; }
                &-4 { margin-top: 40px !important; margin-bottom: 40px !important; }
                &-5 { margin-top: 50px !important; margin-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { margin-top:  0   !important; margin-bottom:  0   !important; }
                &-1 { margin-top: 10px !important; margin-bottom: 10px !important; }
                &-2 { margin-top: 20px !important; margin-bottom: 20px !important; }
                &-3 { margin-top: 30px !important; margin-bottom: 30px !important; }
                &-4 { margin-top: 40px !important; margin-bottom: 40px !important; }
                &-5 { margin-top: 50px !important; margin-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { margin-top:  0   !important; margin-bottom:  0   !important; }
                &-1 { margin-top: 10px !important; margin-bottom: 10px !important; }
                &-2 { margin-top: 20px !important; margin-bottom: 20px !important; }
                &-3 { margin-top: 30px !important; margin-bottom: 30px !important; }
                &-4 { margin-top: 40px !important; margin-bottom: 40px !important; }
                &-5 { margin-top: 50px !important; margin-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { margin-top:  0   !important; margin-bottom:  0   !important; }
                &-1 { margin-top: 10px !important; margin-bottom: 10px !important; }
                &-2 { margin-top: 20px !important; margin-bottom: 20px !important; }
                &-3 { margin-top: 30px !important; margin-bottom: 30px !important; }
                &-4 { margin-top: 40px !important; margin-bottom: 40px !important; }
                &-5 { margin-top: 50px !important; margin-bottom: 50px !important; }
            }
        }
    }

    /** LEFT & RIGHT
    ------------------------------ */
    &x {
        &-0 { margin-left:  0   !important; margin-right:  0   !important; }
        &-1 { margin-left: 10px !important; margin-right: 10px !important; }
        &-2 { margin-left: 20px !important; margin-right: 20px !important; }
        &-3 { margin-left: 30px !important; margin-right: 30px !important; }
        &-4 { margin-left: 40px !important; margin-right: 40px !important; }
        &-5 { margin-left: 50px !important; margin-right: 50px !important; }

        &-auto { margin-left: auto !important; margin-right: auto !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { margin-left:  0   !important; margin-right:  0   !important; }
                &-1 { margin-left: 10px !important; margin-right: 10px !important; }
                &-2 { margin-left: 20px !important; margin-right: 20px !important; }
                &-3 { margin-left: 30px !important; margin-right: 30px !important; }
                &-4 { margin-left: 40px !important; margin-right: 40px !important; }
                &-5 { margin-left: 50px !important; margin-right: 50px !important; }

                &-auto { margin-left: auto !important; margin-right: auto !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { margin-left:  0   !important; margin-right:  0   !important; }
                &-1 { margin-left: 10px !important; margin-right: 10px !important; }
                &-2 { margin-left: 20px !important; margin-right: 20px !important; }
                &-3 { margin-left: 30px !important; margin-right: 30px !important; }
                &-4 { margin-left: 40px !important; margin-right: 40px !important; }
                &-5 { margin-left: 50px !important; margin-right: 50px !important; }

                &-auto { margin-left: auto !important; margin-right: auto !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { margin-left:  0   !important; margin-right:  0   !important; }
                &-1 { margin-left: 10px !important; margin-right: 10px !important; }
                &-2 { margin-left: 20px !important; margin-right: 20px !important; }
                &-3 { margin-left: 30px !important; margin-right: 30px !important; }
                &-4 { margin-left: 40px !important; margin-right: 40px !important; }
                &-5 { margin-left: 50px !important; margin-right: 50px !important; }

                &-auto { margin-left: auto !important; margin-right: auto !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { margin-left:  0   !important; margin-right:  0   !important; }
                &-1 { margin-left: 10px !important; margin-right: 10px !important; }
                &-2 { margin-left: 20px !important; margin-right: 20px !important; }
                &-3 { margin-left: 30px !important; margin-right: 30px !important; }
                &-4 { margin-left: 40px !important; margin-right: 40px !important; }
                &-5 { margin-left: 50px !important; margin-right: 50px !important; }

                &-auto { margin-left: auto !important; margin-right: auto !important; }
            }
        }
    }
}

/** PADDING
-------------------------------------------------------------------------------- */

.p {

    /** TOP
    ------------------------------ */
    &t {
        &-0 { padding-top:  0   !important; }
        &-1 { padding-top: 10px !important; }
        &-2 { padding-top: 20px !important; }
        &-3 { padding-top: 30px !important; }
        &-4 { padding-top: 40px !important; }
        &-5 { padding-top: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { padding-top:  0   !important; }
                &-1 { padding-top: 10px !important; }
                &-2 { padding-top: 20px !important; }
                &-3 { padding-top: 30px !important; }
                &-4 { padding-top: 40px !important; }
                &-5 { padding-top: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { padding-top:  0   !important; }
                &-1 { padding-top: 10px !important; }
                &-2 { padding-top: 20px !important; }
                &-3 { padding-top: 30px !important; }
                &-4 { padding-top: 40px !important; }
                &-5 { padding-top: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { padding-top:  0   !important; }
                &-1 { padding-top: 10px !important; }
                &-2 { padding-top: 20px !important; }
                &-3 { padding-top: 30px !important; }
                &-4 { padding-top: 40px !important; }
                &-5 { padding-top: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { padding-top:  0   !important; }
                &-1 { padding-top: 10px !important; }
                &-2 { padding-top: 20px !important; }
                &-3 { padding-top: 30px !important; }
                &-4 { padding-top: 40px !important; }
                &-5 { padding-top: 50px !important; }
            }
        }
    }

    /** BOTTOM
    ------------------------------ */
    &b {
        &-0 { padding-bottom:  0   !important; }
        &-1 { padding-bottom: 10px !important; }
        &-2 { padding-bottom: 20px !important; }
        &-3 { padding-bottom: 30px !important; }
        &-4 { padding-bottom: 40px !important; }
        &-5 { padding-bottom: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { padding-bottom:  0   !important; }
                &-1 { padding-bottom: 10px !important; }
                &-2 { padding-bottom: 20px !important; }
                &-3 { padding-bottom: 30px !important; }
                &-4 { padding-bottom: 40px !important; }
                &-5 { padding-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { padding-bottom:  0   !important; }
                &-1 { padding-bottom: 10px !important; }
                &-2 { padding-bottom: 20px !important; }
                &-3 { padding-bottom: 30px !important; }
                &-4 { padding-bottom: 40px !important; }
                &-5 { padding-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { padding-bottom:  0   !important; }
                &-1 { padding-bottom: 10px !important; }
                &-2 { padding-bottom: 20px !important; }
                &-3 { padding-bottom: 30px !important; }
                &-4 { padding-bottom: 40px !important; }
                &-5 { padding-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { padding-bottom:  0   !important; }
                &-1 { padding-bottom: 10px !important; }
                &-2 { padding-bottom: 20px !important; }
                &-3 { padding-bottom: 30px !important; }
                &-4 { padding-bottom: 40px !important; }
                &-5 { padding-bottom: 50px !important; }
            }
        }
    }

    /** LEFT
    ------------------------------ */
    &l {
        &-0 { padding-left:  0   !important; }
        &-1 { padding-left: 10px !important; }
        &-2 { padding-left: 20px !important; }
        &-3 { padding-left: 30px !important; }
        &-4 { padding-left: 40px !important; }
        &-5 { padding-left: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { padding-left:  0   !important; }
                &-1 { padding-left: 10px !important; }
                &-2 { padding-left: 20px !important; }
                &-3 { padding-left: 30px !important; }
                &-4 { padding-left: 40px !important; }
                &-5 { padding-left: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { padding-left:  0   !important; }
                &-1 { padding-left: 10px !important; }
                &-2 { padding-left: 20px !important; }
                &-3 { padding-left: 30px !important; }
                &-4 { padding-left: 40px !important; }
                &-5 { padding-left: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { padding-left:  0   !important; }
                &-1 { padding-left: 10px !important; }
                &-2 { padding-left: 20px !important; }
                &-3 { padding-left: 30px !important; }
                &-4 { padding-left: 40px !important; }
                &-5 { padding-left: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { padding-left:  0   !important; }
                &-1 { padding-left: 10px !important; }
                &-2 { padding-left: 20px !important; }
                &-3 { padding-left: 30px !important; }
                &-4 { padding-left: 40px !important; }
                &-5 { padding-left: 50px !important; }
            }
        }
    }

    /** RIGHT
    ------------------------------ */
    &r {
        &-0 { padding-right:  0   !important; }
        &-1 { padding-right: 10px !important; }
        &-2 { padding-right: 20px !important; }
        &-3 { padding-right: 30px !important; }
        &-4 { padding-right: 40px !important; }
        &-5 { padding-right: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { padding-right:  0   !important; }
                &-1 { padding-right: 10px !important; }
                &-2 { padding-right: 20px !important; }
                &-3 { padding-right: 30px !important; }
                &-4 { padding-right: 40px !important; }
                &-5 { padding-right: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { padding-right:  0   !important; }
                &-1 { padding-right: 10px !important; }
                &-2 { padding-right: 20px !important; }
                &-3 { padding-right: 30px !important; }
                &-4 { padding-right: 40px !important; }
                &-5 { padding-right: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { padding-right:  0   !important; }
                &-1 { padding-right: 10px !important; }
                &-2 { padding-right: 20px !important; }
                &-3 { padding-right: 30px !important; }
                &-4 { padding-right: 40px !important; }
                &-5 { padding-right: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { padding-right:  0   !important; }
                &-1 { padding-right: 10px !important; }
                &-2 { padding-right: 20px !important; }
                &-3 { padding-right: 30px !important; }
                &-4 { padding-right: 40px !important; }
                &-5 { padding-right: 50px !important; }
            }
        }
    }

    /** TOP & BOTTOM
    ------------------------------ */
    &y{
        &-0 { padding-top:  0   !important; padding-bottom:  0   !important; }
        &-1 { padding-top: 10px !important; padding-bottom: 10px !important; }
        &-2 { padding-top: 20px !important; padding-bottom: 20px !important; }
        &-3 { padding-top: 30px !important; padding-bottom: 30px !important; }
        &-4 { padding-top: 40px !important; padding-bottom: 40px !important; }
        &-5 { padding-top: 50px !important; padding-bottom: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { padding-top:  0   !important; padding-bottom:  0   !important; }
                &-1 { padding-top: 10px !important; padding-bottom: 10px !important; }
                &-2 { padding-top: 20px !important; padding-bottom: 20px !important; }
                &-3 { padding-top: 30px !important; padding-bottom: 30px !important; }
                &-4 { padding-top: 40px !important; padding-bottom: 40px !important; }
                &-5 { padding-top: 50px !important; padding-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { padding-top:  0   !important; padding-bottom:  0   !important; }
                &-1 { padding-top: 10px !important; padding-bottom: 10px !important; }
                &-2 { padding-top: 20px !important; padding-bottom: 20px !important; }
                &-3 { padding-top: 30px !important; padding-bottom: 30px !important; }
                &-4 { padding-top: 40px !important; padding-bottom: 40px !important; }
                &-5 { padding-top: 50px !important; padding-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { padding-top:  0   !important; padding-bottom:  0   !important; }
                &-1 { padding-top: 10px !important; padding-bottom: 10px !important; }
                &-2 { padding-top: 20px !important; padding-bottom: 20px !important; }
                &-3 { padding-top: 30px !important; padding-bottom: 30px !important; }
                &-4 { padding-top: 40px !important; padding-bottom: 40px !important; }
                &-5 { padding-top: 50px !important; padding-bottom: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { padding-top:  0   !important; padding-bottom:  0   !important; }
                &-1 { padding-top: 10px !important; padding-bottom: 10px !important; }
                &-2 { padding-top: 20px !important; padding-bottom: 20px !important; }
                &-3 { padding-top: 30px !important; padding-bottom: 30px !important; }
                &-4 { padding-top: 40px !important; padding-bottom: 40px !important; }
                &-5 { padding-top: 50px !important; padding-bottom: 50px !important; }
            }
        }
    }

    /** LEFT & RIGHT
    ------------------------------ */
    &x {
        &-0 { padding-left:  0   !important; padding-right:  0   !important; }
        &-1 { padding-left: 10px !important; padding-right: 10px !important; }
        &-2 { padding-left: 20px !important; padding-right: 20px !important; }
        &-3 { padding-left: 30px !important; padding-right: 30px !important; }
        &-4 { padding-left: 40px !important; padding-right: 40px !important; }
        &-5 { padding-left: 50px !important; padding-right: 50px !important; }

        @media (min-width: $screen-xs-min) {
            &-xs {
                &-0 { padding-left:  0   !important; padding-right:  0   !important; }
                &-1 { padding-left: 10px !important; padding-right: 10px !important; }
                &-2 { padding-left: 20px !important; padding-right: 20px !important; }
                &-3 { padding-left: 30px !important; padding-right: 30px !important; }
                &-4 { padding-left: 40px !important; padding-right: 40px !important; }
                &-5 { padding-left: 50px !important; padding-right: 50px !important; }
            }
        }

        @media (min-width: $screen-sm-min) {
            &-sm {
                &-0 { padding-left:  0   !important; padding-right:  0   !important; }
                &-1 { padding-left: 10px !important; padding-right: 10px !important; }
                &-2 { padding-left: 20px !important; padding-right: 20px !important; }
                &-3 { padding-left: 30px !important; padding-right: 30px !important; }
                &-4 { padding-left: 40px !important; padding-right: 40px !important; }
                &-5 { padding-left: 50px !important; padding-right: 50px !important; }
            }
        }

        @media (min-width: $screen-md-min) {
            &-md {
                &-0 { padding-left:  0   !important; padding-right:  0   !important; }
                &-1 { padding-left: 10px !important; padding-right: 10px !important; }
                &-2 { padding-left: 20px !important; padding-right: 20px !important; }
                &-3 { padding-left: 30px !important; padding-right: 30px !important; }
                &-4 { padding-left: 40px !important; padding-right: 40px !important; }
                &-5 { padding-left: 50px !important; padding-right: 50px !important; }
            }
        }

        @media (min-width: $screen-lg-min) {
            &-lg {
                &-0 { padding-left:  0   !important; padding-right:  0   !important; }
                &-1 { padding-left: 10px !important; padding-right: 10px !important; }
                &-2 { padding-left: 20px !important; padding-right: 20px !important; }
                &-3 { padding-left: 30px !important; padding-right: 30px !important; }
                &-4 { padding-left: 40px !important; padding-right: 40px !important; }
                &-5 { padding-left: 50px !important; padding-right: 50px !important; }
            }
        }
    }
}
