.login-page {
    .hero {
        h1 {
            font-size: 44px;
            font-weight: bolder;
        }

        p {
            font-size:30px;
        }

        @media(max-width: $screen-sm-max) {
            padding-left:80px;
        }
    }

    p {
        &.lead {
            margin-bottom:10px;
        }
    }

    .well {
        margin-top:30px;
        padding: 28px;
        padding-bottom:18px;

        &.dark-well {
            background-color: #4a4d4e;
            color: white;

            a {
                color: white;
            }

            p {
                color:white;

                strong {
                    color: white;
                }
            }
        }
    }

    .form-horizontal {
        .control-label {
            text-align: left;
        }
    }

    h2 {
        color: $red;
        font-size: 22px;
        font-weight: bold;
        margin: 0 0 20px;
    }

    img {
        margin-bottom: 15px;
    }
}

.login-header {
    background-size:cover;
    height:358px;
    position:relative;
    background-position-x: center;
}

.login-logo {
    position: absolute;
    top:0;
    right:0;
    background: #fff;
    padding:10px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .3);
}

.well {
    border: 0;
    border-radius: 0;
    @include box-shadow(inset, 0, 0, 0, transparent);
}

.contact-footer {
    background-color: #4a4d4e;
    color: white;

    a {
        color: white;
    }

    p {
        color:white;

        strong {
            color: white;
        }
    }

    padding: 15px 0 5px;
    margin: 50px 0 0;
}
