.faq {
    margin: 0;
    padding: 0;

    li.fancy-collapse {
        list-style-type: none;
        padding: 0;
        margin: 0;

        a {
            border-radius: $border-radius-base;
            padding: 10px 15px;
            font-size: $font-size-m;
            display: block;
            background-color: lighten($lighter-grey, 10%);
            margin-bottom: 15px;
            margin-top: 20px;

            &:hover {
                text-decoration: none;
                background-color: lighten($lighter-grey, 4%);
            }

            &:active, &:focus, &:visited {
                text-decoration: none;
            }

            &.question-collapse-controller:after {
                font-family: $font-awesome;
                font-weight: 900;
                content: "\f078";
                position: absolute;
                right: 30px;
            }

            &.question-collapse-controller.collapsed:after {
                content: "\f053";
            }
        }

        div {
            font-size: $font-size-m;
            margin: 10px 15px;
            font-weight: 300;
        }

        &:first-of-type {
            a {
                margin-top: 0;
            }
        }
    }
}
