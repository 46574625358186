.form-additional-information-wrapper {
    display: flex;

    .form-additional-information-wrapper--input {
        flex: 2;
    }
}

.additional-information-icon {
    margin-left: 8px;

    &:hover {
        cursor: pointer;
    }
}
