.btn {
    &.btn-light {
        background-color: $brand-primary;
        color: white;

        &:hover {
            background-color: $brand-primary;
            color: white;
        }
        &:active {
            background-color: $brand-primary;
            color: white;
        }
        &:focus {
            background-color: $brand-primary;
            color: white;
        }
    }
}
