.img-full-width {
    width:100%;
    height:auto;
}

.homepage-slider {
    margin-bottom:25px;

    .carousel-control {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 15%;
        opacity: 0.5;
        filter: none;
        font-size: 40px;
        color: #ffffff;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
        background-color: transparent;

        &.left {
            background-image: none;
            filter: none;
            text-align:left;
        }

        &.right {
            left: auto;
            right: 0;
            background-image: none;
            filter: none;
            text-align:right;
        }

        .icon-prev {
            left:25%;
            font-size:40px;
        }

        .icon-next {
            right:25%;
            font-size:40px;
        }
    }
}

.carousel-controls {
    padding-top:10px;

    #slider-toggle {
        margin: 0;
    }
}

.carousel-control .icon-prev:before {
    content: '\2039';
}
.carousel-control .icon-next:before {
    content: '\203a';
}

.carousel-indicators {
    text-align:center;
}

.startsite_content {
    .devices {
        width:100%;
        .device {
            background-color: #FFF;
            &.panel {
                padding:0;

                .panel-footer {
                    background-color: white;
                    border-top:0;
                    padding-top:0;
                }
            }
            .device-content {
                .img {
                    padding: 10px;
                    margin: -10px -10px 0;
                    background-color: $well-bg;
                }

                .title {
                    margin: 20px 0;

                    a {
                        color: $text-color;
                    }
                    small {
                        display: block;
                    }
                }
            }

            .button-wrapper {
                margin-top: 20px;
                padding-top: 20px;
                border-top: 1px solid $panel-inner-border;
            }
        }
    }
}

.slick-slide-box {

    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 30px;
        margin-top: -54px;

        &.slick-next {
            right:-50px;
        }

        &.slick-prev {
            left:-50px;
        }
    }
}

.welcome-message {
    text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}
