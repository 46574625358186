.product-gallery{
    &-loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin: 70px 0;

        &.hide {
            display: none;
        }
    }

    &-extol {
        margin-bottom: 20px;

        &-img {
            width: 100%;
            height: auto;
        }
    }

    &-preview {
        text-align: center;
        margin-bottom: 15px;

        &-img {
            width: 92px;
            height: 92px;
            cursor: pointer;
            padding: 5px;
            margin: 5px;
            border: 1px solid $gray;

            &.active {
                border-color: $brand-primary;
            }
        }
    }
}
