// tetranz/select2entity-bundle select field styles

.select2 {
    &-container {
        .select2-selection--single {
            height: 34px;

            .select2-selection__arrow {
                height: 32px;
            }
        }

        &--default {
            .select2-selection--single {
                border-color: #ccc;

                .select2-selection__rendered {
                    line-height: 34px;
                }
            }
        }
    }
}
