.devices {
    .device.topseller {

        border: 1px solid $brand-primary;

        span.badge.topseller {
            @include topseller-badge($brand-primary, white);
        }
    }
    .device {
        display: table;
        position: relative;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        margin-bottom: 30px;

        background-color: $light-gray;
        /*background: #ffffff;  Old browsers */
        /*#gradient@include vertical(#ffffff, #f3f3f3); */

        .title {
            font-size: 20px;
            margin-top: 10px;
            margin-bottom: 10px;
            height: 45px;
        }

        .variants {
            min-height: 66px;

            .form-group {
                margin: 0px;
            }
        }

        .img {
            @extend .text-center;
        }

        .price {
            margin: 5px 0;

            span {
                float: right;
                font-weight: 700;
                font-size: 16px;
                position: relative;
                top: -2px;

                &.score-out:after {
                    height: 2px;
                    background-color: $red;
                    left: -5px;
                    right: -5px;
                    top: 50%;
                    content: "";
                    position: absolute;
                    @include rotate(-10deg);
                }
            }
        }
    }
}

.device-detail-hover {
    position: relative;
    display: inline-block;

    &:after {
        opacity: 0;
        -webkit-transition: all 350ms ease-in-out;
        -moz-transition: all 350ms ease-in-out;
        -o-transition: all 350ms ease-in-out;
        transition: all 350ms ease-in-out;
        content: "\f05a";
        position: absolute;
        color: lighten($gray-dark, 50%);
        text-align: center;
        font-size: $font-size-large * 5;
        left: 50%;
        top: 50%;
        font-family: $font-awesome;
        font-weight: 900;
        cursor: pointer;
        margin-top: -60px;
        margin-left: -45px;
        height: 120px;
        width: 90px;
    }

    &:hover {
        img {
            opacity: 0.5;
        }
        &:after {
            opacity: 0.8;
        }
    }
}
