/*  TARIFF PANEL
---------------------------------------------------------- */

.tariff-wrapper {
    .tariff {
        position: relative;
        border-radius: 0;

        .badge.topseller {
            @include topseller-badge($brand-primary, white);
        }

        .panel {
            &-heading {
                border-bottom: none;

                .tariff-name {
                    color: $brand-primary;
                    font-size: 24px;
                    font-weight: bold;

                    .brand {
                        color: $gray;
                        font-weight: normal;
                        font-size: $font-size-large;
                    }

                    > a {
                        cursor: help;
                    }
                }
            }

            &-body {
                .tariff-short-description {
                    font-size: $font-size-large;
                }
            }

            &-footer {
                background-color: transparent;
            }
        }
    }
}
