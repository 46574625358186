@import '_mixins';

body {
    .pagination-list {
        margin-top: 0px;
    }

    input[type=date]::-webkit-inner-adjust-hue-button,
    input[type=date]::-webkit-outer-adjust-hue-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .row-buffer,
    .row-buffer-1x {
        margin-bottom: $padding-large-vertical;
    }

    .row-buffer-2x {
        margin-bottom: $padding-large-vertical * 2;
    }

    .row-buffer-3x {
        margin-bottom: $padding-large-vertical * 3;
    }

    .row-buffer-4x {
        margin-bottom: $padding-large-vertical * 4;
    }

    .row-buffer-5x {
        margin-bottom: $padding-large-vertical * 5;
    }

    .row-buffer-6x {
        margin-bottom: $padding-large-vertical * 6;
    }

    .row-buffer-7x {
        margin-bottom: $padding-large-vertical * 7;
    }

    .row-buffer {
        &:last-child {
            margin-bottom: 0px;
        }
    }
}

@import 'Helper/_button';
@import 'Helper/_sidebar';
@import 'Helper/_form';
@import 'Helper/_list';
@import 'Helper/_carousel';
@import 'Helper/_panel';
@import 'Helper/_product-gallery';
@import 'Helper/_flap';
@import 'Helper/_border';
@import 'Helper/_spacing';
@import 'Helper/_text';
@import 'Helper/_images';

//@import '../../lib/jquery-ui-less-master/less/jquery.ui.less';

@import 'Widget/_ck_editor';
@import 'Widget/_contact';

@import 'Parts/_additional-informations';
@import 'Parts/_price';

//libs
