/** DEFAULT ICON STYLES
--------------------------------------------------------- */

.icn {
    display: inline-block;
    vertical-align: middle;
    width: $icn-font-default-size;
    height: $icn-font-default-size;
    stroke: $icn-font-default-color;

    &-bubble {
        display: inline-block;
        width: round($icn-font-default-bubble-size / 1.5);
        height: round($icn-font-default-bubble-size / 1.5);
        line-height: round($icn-font-default-bubble-size / 1.5);
        text-align: center;
        border-radius: 50%;
        background-color: $icn-font-default-bubble-background-color;
        transition: background .4s ease-in-out;

        @media (min-width: $screen-sm-min) {
            width: $icn-font-default-bubble-size;
            height: $icn-font-default-bubble-size;
            line-height: $icn-font-default-bubble-size;

            .icn {
                width: $icn-font-default-bubble-icon-size;
                height: $icn-font-default-bubble-icon-size;
            }
        }
    }
}

// apply hover effect when in parent div
*:hover > .icn-bubble {
    background-color: $icn-font-default-bubble-background-color-hover;

    .icn {
        stroke: $icn-font-default-bubble-color-hover;
    }
}

/** SIZES
--------------------------------------------------------- */

@each $size, $value in $icn-font-sizes {
    .icn-size-#{$size} {
        width:  $value !important;
        height: $value !important;
    }

    .icn-bubble-#{$size} {
        width:  round($value * 2) !important;
        height: round($value * 2) !important;
        line-height: round($value * 2) !important;

        .icn {
            width:  $value !important;
            height: $value !important;
        }
    }
}

/** COLORS
--------------------------------------------------------- */

@each $color, $value in $theme-colors {
    .icn-color-#{$color} {
        stroke: $value !important;
    }
}
