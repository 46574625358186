.nav > li.dropdown-content.open {
    position: static;
}

.nav > li.dropdown-content.open .dropdown-menu {
    display: table;
    width: 100%;
    left: 0;
    right: 0;
}

.pagination {
    margin-bottom: 0px;
}

/*.nav > li.dropdown-content.open .dropdown-menu > li {*/
/*display: table-cell;*/
/*}*/