:root {
    --cb-flap-width: 35px;
    --cb-flap-height: 54px;
    --cb-flap-popup-width: 300px;
    --cb-flap-popup-height: 300px;
    --cb-border-radius: 4px;
}

a.bang-popup.corporatebenefits-flap {
    position: absolute;
    top: 300px;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: var(--cb-flap-width);
    height: var(--cb-flap-height);
}

a.bang-popup.corporatebenefits-flap * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

a.bang-popup.corporatebenefits-flap>span.flap {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 10px;
    background-color: #fff;
    border-top-right-radius: val(--cb-border-radius);
    border-bottom-right-radius: val(--cb-border-radius);
   
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    
    -webkit-transition: all ease-in-out .5s;
    transition: all ease-in-out .5s;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .5);
}

a.bang-popup.corporatebenefits-flap img {
    max-width: 100%;
    height: auto;
}

a.bang-popup.corporatebenefits-flap .popup {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    display: block;
    width: var(--cb-flap-popup-width);
    height: var(--cb-flap-popup-height);
    -webkit-transition: all ease-in-out .5s;
    transition: all ease-in-out .5s;

    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .5);

    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .5);

    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .5);

    border-top-left-radius: val(--cb-border-radius);
    border-bottom-right-radius: val(--cb-border-radius);
    border-bottom-left-radius: val(--cb-border-radius);

}

a.bang-popup.corporatebenefits-flap:hover>span.flap {
    -webkit-transform: translateX(var(--cb-flap-popup-width));
    transform: translateX(var(--cb-flap-popup-width));
}

a.bang-popup.corporatebenefits-flap:hover .popup {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}