@font-face {
  font-family: 'Vodafone';
  src: url("../../../fonts/themes/vodafone/new/VodafoneRg.woff2") format("woff2"), url("../../../fonts/themes/vodafone/new/VodafoneRg.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family:'Vodafone';
  src:url("../../../fonts/themes/vodafone/new/vodafonergbd-webfont.woff2") format("woff2"), url("../../../fonts/themes/vodafone/new/vodafonergbd-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Vodafone';
  src: url("../../../fonts/themes/vodafone/new/VodafoneLt.woff2") format("woff2"), url("../../../fonts/themes/vodafone/new/VodafoneLt.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap
}
