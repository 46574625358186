.vvl-login {
    .hero {
        h1, p {
            color: #fff;
        }
    }
}

/*.vvl-login {
	background: $red no-repeat left top;

	@media (min-width: $screen-sm-min) {
		margin-top: 32px;
		box-shadow: 0px 0px 20px #999;
		background-position: left top;
	}

	h1 {
		font-family: 'Vodafone', sans-serif;
		color: #fff;
		font-size: $font-size-xl;
		font-weight: bold;
	}

	h2 {
		font-size: $font-size-default;
		font-weight: bold;
	}

	.header {
		padding: 122px 0 16px;
		background: transparent no-repeat -50px top;

		@media (max-width: $screen-xs-max) {
			.hero {
				font-size: 22px;
			}

			h1 {
				font-size: $font-size-l;
			}
		}

		@media (min-width: $screen-xs-min) {
			padding: 88px 88px 16px;
		}

		@media (min-width: $screen-lg-min) {
			padding-top: 150px;
			background-position: left top;
		}
	}

	.hero {
		font-size: $font-size-l;
		color: $white;
		text-shadow: 2px 2px 6px #333;
	}

	.teaser {
		background: $red;
		color: $white;
		padding: 0 0 20px;

		@media (min-width: $screen-xs-min) {
			padding: 0 88px 20px;
		}

		img {
			width: 100%;
			display: block;
			box-sizing: content-box;
			margin: 12px 0;

			@media (min-width: $screen-sm-min) {
				border: 8px solid $red;
				border-bottom: none;
				margin: -23px -8px 12px;
			}
		}
	}

	.footer {
		background: $gray;
		font-size: $font-size-s;
		padding: 15px 10px 0;

		@media (min-width: $screen-xs-min) {
			padding: 15px 10px 0 88px;
		}

		&, a {
			color: $white;
		}

		strong {
			text-transform: uppercase;
		}
	}

	.login {

		.alert .help-block {
			margin: 0;
			padding: 0;
			list-style: none;
			color: inherit;
		}

		&, label {
			color: $white;

			&.required:after {
				content: '*';
			}
		}

		.form-control {
			background-color: #cebebb;
			border-radius: 0;
			color: #000;
			font-size: $font-size-default;
		}

		.input-group-addon {
			border: 0;
			background: none;
			color: $white;
			font-weight: bold;
			font-size: $font-size-default;
		}

		&.success {
			color: #000;
			background: #eee;
			border: 2px solid rgba(200, 200, 200, 1);

			@media (min-width: $screen-xs-min) {
				background: rgba(255, 255, 255, .75);
				border: 2px solid rgba(200, 200, 200, .75);
				border-radius: 2px;
			}

			padding: 20px 0;
			margin-bottom: 80px;
		}

		.form-group {
			position: relative;

			.help-block {
				pointer-events: none;
				position: absolute;
				top: 32px;
				left: 12px;
				list-style: none;
				margin: 0;
				padding: 0;
				color: $red;
			}

			&.mobile .help-block {
				left: 61px;
				z-index: 1000;
			}
		}
	}

	.btn {
		font-size: $font-size-m;
		text-shadow: 2px 2px 4px #999;
		width: 180px;
	}

	.red {
		color: $red;
	}

	.imprint {
		border-top: 1px solid $light-gray;
		margin-top: 20px;
		padding: 20px 10px;

		&, h3 {
			font-size: $font-size-s;
		}

		h3 {
			margin: 0 0 10px;
		}

		a {
			color: $gray;
			text-decoration: underline;

			&:focus,
			&:hover {
				text-decoration: none;
			}
		}
	}

	@media (min-width: $screen-sm-min) {
		#vvl_login_submit {
			margin: 24px 0 0;
		}
	}

	.content {
		padding: 0 0 20px;

		@media (min-width: $screen-xs-min) {
			padding: 0 88px 20px;
		}

		h2 {
			font-size: 22px;
			color: $red;
		}
	}

	.register-box {
		border-top: 1px solid $lighter-grey;
		margin: 50px 0;
	}

	#login {
		background: $lighter-grey;
		padding: 30px 45px 5px;
		margin: 25px 0 50px;

		.control-label {
			text-align: left;
		}

		a {
			color: $gray;
		}
	}

	.login-page {
		background: transparent no-repeat center top;
		box-shadow: none;
		margin-top: 0;
		overflow: hidden;

		.header,
		.content,
		.footer {
			padding-left: 60px;
			padding-right: 60px;
		}

		.header {
			height: 358px;
			padding-top: 100px;
			margin-bottom: 20px;
			background: none;
		}

		.footer {
			background: none;

			.login-help {
				margin-top: -4px;

				strong {
					font-size: $font-size-m;
					color: $red;
					text-transform: none;
					line-height: 24px;
				}
			}

			&, a {
				color: $gray;
			}
		}

		.hero {
			color: $red;
			text-shadow: none;
			font-size: 30px;

			h1 {
				margin-bottom: 0;
				font-size: 44px;
			}
		}
	}

	.rhombus {
		position: absolute;
		height: 33px;
		background: $red;
		width: 1999px;
		left: -1999px;
		top: -15px;
		text-align: right;

		img {
			position: relative;
			z-index: 10;
			top: -14px;
			left: 6px;
		}

		&:before,
		&:after {
			position: absolute;
			right: 0;
		}

		&:before {
			content: "";
			border-bottom: 1999px solid #e60000;
			border-right: 1999px inset transparent;
			border-left: none;
			border-top: none;
			bottom: 33px;
		}

		&:after {
			content: "";
			border-top: 1999px solid #e60000;
			border-right: 1999px inset transparent;
			top: 33px;
		}
	}
}*/
