.selectTarifTable {
    border-collapse: separate;
    border-spacing: 2px;
    width: 100%;

    th, td {
        border: 1px solid #ccc;
        padding: 10px;

        &:first-child {
            background: $red;
            color: #fff;
            font-weight: 700;
        }
    }

    th {
        background: $red;
        border-color: darken($red, 10%);
        color: #fff;
        font-size: 20px;
        border-radius: 3px;
    }
}
