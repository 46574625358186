.tariffmobile {
    border-spacing: 4px;
    border-collapse: separate;
    margin: 20px 0;
    table-layout: fixed;

    &.col2 td {
        width: 50%;
    }

    &.col3 td {
        width: 33.3%;
    }

    &.col4 td {
        width: 25%;
    }

    & > thead > tr > th,
    & > tbody > tr > td {
        border: 0;
    }

    th {
        color: #fff;
        //#gradient > .vertical(#e60000, #5b0000);
        font-size: 22px;
        font-weight: bold;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
        padding: 10px;
        text-align: left;

        small {
            font-size: 14px;
            display: block;
        }
    }

    td {
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
        padding: 10px;
        background: #fff;
        position: relative;
        height: 100%;

        .tarif-link {
            position: absolute;
            bottom: 10px;
        }

        .well {
            padding: 10px;
            margin: 5px -10px;
            border-radius: 0;
            text-align: center;
        }

        ul {
            padding-left: 20px;
        }
    }

    .tarif-preis {
        font-size: 28px;
        font-weight: bold;
        text-align: center;

        div {
            padding: 5px 0;
            font-size: 27px;
        }

        small {
            font-size: 14px;
            font-weight: normal;
        }

        .keyfacts_price_extra {
            font-weight: 400;
            font-size: 12px;
            color: #666;
        }
    }
}
