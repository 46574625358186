.tariffInfo {
    .nav-tabs {
        border: none;

        > li {
            margin-bottom: 0;

            a {
                @extend .btn;
                @extend .btn-default;
                @extend .btn-lg;
                margin: 0 10px 5px 0;
            }

            &.active {
                & > a {
                    @extend .btn-primary;
                    border-color: $btn-primary-border;
                }
            }
        }
    }

    table {
        width: 100% !important;
        border: 0;
        border-collapse: separate;
        border-spacing: 15px 0;

        tr {
            td, th {
                border: none;
                border-left: 1px solid $lighter-grey;
                border-right: 1px solid $lighter-grey;
                background: $light-gray;
                text-align: center;
                padding: 5px 10px;
                width: 16.6%;
                position: relative;

                &:first-child {
                    text-align: right;
                    border: none;
                    border-top: 1px solid $lighter-grey;
                    background: none;
                    width: 17%;
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    border-top: 1px solid $lighter-grey;
                    width: #{"calc(100% - 20px)"};
                    bottom: -1px;
                    z-index: 1;
                }
            }
        }

        thead {
            td, th {
                vertical-align: top;
                text-align: left;
                border: 1px solid $lighter-grey;
                font-size: 24px;
                color: $red;

                &:first-child {
                    border: none;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        tbody {
            &:before {
                content: "";
                display: block;
                height: 15px;
            }

            tr {
                &:first-child {
                    td, th {
                        border-top: 1px solid $lighter-grey;
                    }
                }
                &:last-child {
                    td, th {
                        border-bottom: 1px solid $lighter-grey;
                    }
                }

                &.price {
                    td {
                        font-size: 24px;

                        &:not(:first-child) {
                            color: $red;
                        }
                    }
                }

                &.advantage {
                    td:not(:first-child) {
                        color: #fff;
                        background: $red;
                        font-size: 20px;
                    }
                }
            }

            &.headline-collapse {

                + tbody {

                    display: none;

                    &:before {
                        height: 0;
                    }
                }

                &:before {
                    content: "";
                    display: block;
                    height: 15px;
                }

                tr {
                    &:first-child {
                        td, th {
                            border-top: 1px solid $lighter-grey;
                        }
                    }
                    &:last-child {
                        td, th {
                            border-bottom: 0px solid $lighter-grey;
                        }
                    }
                    td, th {
                        background-color: $brand-primary;
                        color: white;
                        font-weight: 600;
                        cursor:pointer;

                        &:first-of-type {
                            border-bottom: 0;
                            border-top: 0;
                            background-color: transparent;
                            color: transparent;
                        }

                        &:after {
                            border-top: 0;
                        }
                    }
                }

                &.black {
                    tr {
                        td, th {
                            background-color: $text-color;

                            &:first-of-type {
                                border-bottom: 0;
                                border-top: 0;
                                background-color: transparent;
                                color: transparent;
                            }
                        }
                    }
                }
            }
        }

        tfoot {
            &:before {
                content: "";
                display: block;
                height: 15px;
            }

            td, th {
                vertical-align: top;
                text-align: left;
                border: none;
                background: none;

                &:first-child {
                    border: none;
                }

                &:after {
                    content: none;
                }
            }

            a {
                color: #333;

                &:before {
                    content: "> ";
                    font-family: $font-awesome;
                    font-weight: 900;
                }
            }
        }
    }
}
