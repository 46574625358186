@import '../../import/_variables';

//== Colors
$red: #E60000;
$green: #427d00;
$white: #FFFFFF;
$light-gray: #f3f3f3;
$gray: #4a4d4e;
$gray-dark: #3d3d3d;
$darker-red: #d10612;
$lighter-red: #f3979c;
$lighter-grey: #d8d8d8;
$font-size-default: 16px;
$font-size-s: 14px;
$font-size-m: 18px;
$font-size-l: 32px;
$font-size-xl: 49px;

$btn-dark-grey-color: $white;
$btn-dark-grey-bg: $gray;
$btn-dark-grey-border: $gray-dark;

/*$alert-border-radius: 0;
$alert-padding: 5px 15px;
$alert-danger-bg: transparentize($state-danger-bg, .2);
*/

$brand-primary: $red;
$brand-success: $green;

//== Scaffolding
$text-color: $gray-dark;
$link-color: $brand-primary;

//== Typography
$font-family-base: "Vodafone", Arial, Helvetica, sans-serif;
$font-family-lite: "Vodafone", Arial, Helvetica, sans-serif;
$font-size-base: 14px;

//== Navbar
//
//##
$navbar-default-color: #fff;
$navbar-default-bg: $brand-primary;
$navbar-default-border: darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color:                white;
$navbar-default-link-hover-color:          darken($navbar-default-link-color, 6.5%);
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         #ddd;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color:       #ccc;
$navbar-default-link-disabled-bg:          transparent;

$navbar-default-brand-color: #fff;

$gray-border: #ddd;
$light-green: #428600;
$green: #426200;

// Device Details
$device-detail-color-box-widh: 40px;
$device-detail-color-box-height: 40px;
