@mixin box-shadow($h-length, $v-length, $blur, $spread-radius, $shadow-color){
    -webkit-box-shadow: $h-length $v-length $blur $spread-radius $shadow-color;
    -moz-box-shadow: $h-length $v-length $blur $spread-radius $shadow-color;
    box-shadow: $h-length $v-length $blur $spread-radius $shadow-color;
}

@mixin slide-thumbnail-mixin($slide-thumb-width, $slide-thumb-height){
    text-align: center;
    border-radius:0;
    color: $red;
    width: $slide-thumb-width;
    height: $slide-thumb-height;
    border: 1px solid $gray-border;
    margin-left: 10px;
    font-weight: bold;
    font-size: 12px;
    float: left;
    padding-top: 5px;
}

@mixin slide-navigation-mixin(){
    background: $gray-lighter;
    color: $gray-dark;
    font-size: 32px;
    padding-top: 7px;
}

@mixin topseller-badge($color, $text-color){
    position: absolute;
    top: -10px;
    left: -11px;
    background-color: $color;
    color: $text-color;
    border-radius: 0;
    font-weight:normal;
    font-size: $font-size-large;
    padding: 9px 18px;

    &:after {
        border-left: 10px solid transparent;
        border-right: 0 solid transparent;
        border-top: 10px solid darken($color, 15%);
        bottom: -10px;
        content: "";
        height: 0;
        left: 0;
        position: absolute;
        width: 0;
    }
}
