small.strikethrough-price,
span.strikethrough-price {
    position: relative;

    margin-left: 8px;

    &:after {
        content: "";

        position: absolute;

        left: -4px;
        right: -4px;
        top: 50%;

        height: 2px;
        background-color: #e60000;
        // transform: rotate(-10deg);

    }
}

.cart .cart-item .name .price small.strikethrough-price,
.cart .cart-sum .name .price small.strikethrough-price {
    display: inline;
}

.price {
    &-lead {
        display: block;
        float: none !important;
        font-size: 45px !important;
        font-weight: 700;
        margin-bottom: -10px;
        margin-right: -10px;

        small,
        sup,
        sub {
            font-size: 16px;
        }

        small {
            font-weight: normal;
        }

        sup {
            top: -18px;
            left: -6px;
        }

        sub {
            left: -15px;
            bottom: 0;
        }
    }

    &-flex {
        display: flex;
        padding-left: 20px;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;
        margin-bottom: 0px;

        small {
            font-weight: bold;
        }

        &.no--padding {
            padding: 0;
        }

        .price-info {
            font-size: 16px;
        }

        .item-price {

            &.is--small {

                font-size: 28px;

                sub {
                    font-size: 12px;
                }
                sup {
                    font-size: 12px;
                    top: -14px;
                }
            }
        }

    }

}
