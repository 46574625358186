@import '~bootstrap-sass/assets/stylesheets/bootstrap-sprockets';
@import '_variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import './../../import/_theme';
@import 'font';

body {
    h1 {
        font-family: $font-family-lite;
        color: $brand-primary;
    }
}

.brutto-price {
    font-weight: normal;
}

.navbar-brand {
    a {
        color: $navbar-default-brand-color;
        &:hover {
            text-decoration: none;
        }
    }
}

//main nav
.navbar-row {
    @media (max-width: $screen-xs-max) {
        margin-top: -45px;
    }
    .navbar-main {
        border: 0;
        @media (min-width: $screen-sm-min) {
            @include box-shadow(0px, 1px, 3px, 0px, rgba(0, 0, 0, 0.3));
        }
        .navbar-form {
            .form-group {
                input {
                    font-weight: 200;
                    font-size: $font-size-large;
                }
            }
        }
        &.navbar-default {
            @media (max-width: $screen-xs-max) {
                .navbar-header {
                    background: #fff;
                    padding-bottom: 20px;
                    .navbar-toggle {
                        z-index: 20;
                        background: $red;
                        border: none;
                        .icon-bar {
                            background: #fff;
                        }
                    }
                }
            }
            .navbar-nav {
                // $todo This works, but is not perfect. I don't now why the navigation behaves like this
                @media (min-width: $screen-sm-min) {
                    margin-top: -37px;
                }

                @media (min-width: $screen-md-min) {
                    margin-top: 0;
                }

                & > li {
                    & > a {
                        font-size: $font-size-large;
                        position: relative;
                        @media (min-width: $screen-sm-min) {
                            &:after {
                                position: absolute;
                                right: 0;
                                top: 10%;
                                bottom: 10%;
                                width: 1px;
                                background-color: $white;
                                content: "";
                            }
                        }
                    }
                    &:last-of-type {
                        & > a {
                            &:after {
                                width: 0;
                                display: none;
                            }
                        }
                    }
                }
            }

            .navbar-collapse {
                .active.first a {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                @media (min-width: $screen-sm-min) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

//user nav
.user-navigation {
    a {
        color: $text-color;
    }
    li.cart .cart[data-count]:after{
        position: absolute;
        right: 10%;
        top: -14%;
        content: attr(data-count);
        font-size: 10px;
        line-height: 1em;
        padding: .3rem;
        text-align: center;
        font-weight: bold;
        color: #fff;
        border-radius: 100%;
        min-width: 15px;
        background-color: $brand-primary;
    }
}

.nav-wrapper {
    position: relative;
    z-index: 100;
}

.square-thumb {
    @extend .text-center;

    height: 60px;
    display: block;
    margin-bottom: 15px;
    &.btn {
        background-color: $gray-lighter;
        border-radius: 0;
        border: 0;
        font-size: $font-size-large*1.4;
        &:hover {
            background-color: darken($gray-lighter, 15%);
        }
    }
    &.tarif-thumb {
        border: 1px solid $brand-primary;
        padding-top: 6px;
        span {
            font-size: $font-size-small;
            color: $brand-primary;
            strong {
                display: block;
                font-size: $font-size-base;
            }
        }
    }
}

.tarif-preview {
    ul {
        &.list-icon {
            padding: 0;
            margin: 0;
            li {
                list-style-type: none;
                padding-left: 30px;
                position: relative;
                margin-bottom: 10px;
                .fa {
                    position: absolute;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    background-color: $brand-primary;
                    color: $white;
                    border-radius: 50%;
                    text-align: center;
                    padding-top: 4px;
                }
            }
        }
    }
    .price {
        font-size: $font-size-large*1.6;
        font-weight: bolder;
        padding-left: 30px;
    }
}

.checkout-progress {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;

    @media (min-width: $screen-md-min) {
        display: -ms-flexbox;
        display: flex;
    }

    .list-group-item {
        position: relative;
        padding: 20px 0;
        text-align: center;
        background: $gray-lighter;
        border: none;

        &.active {
            background-color: $brand-primary;

            a {
                color: white;
            }
        }

        @media (min-width: $screen-md-min) {
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;

            &:after,
            &:before {
                position: absolute;
                top: 0;
                content: "";
                display: block;
                border-top: 30px solid transparent;
                border-bottom: 30px solid transparent;
            }

            &:after {
                right: -30px;
                border-left: 30px solid $gray-lighter;
                z-index: 11;
            }

            &:before {
                top: -5px;
                right: -35px;
                border-left: solid #fff;
                border-width: 35px;
                z-index: 10;
            }

            &.active:after {
                border-left-color: $brand-primary;
            }

            &:last-child {
                &:before,
                &:after {
                    content: unset;
                }
            }
        }
    }
}

.cart {
    .panel-heading {
        color: $brand-primary;
        font-weight: bold;
    }
    .cart-item,
    .cart-sum {
        @include clearfix;
        .name {
            position: relative;
            display: block;
            padding: 0 0 15px 0;
            .price {
                position: relative;
                right: 0;
                text-align: right;
                float: right;
                small {
                    display: block;
                }
            }
        }
    }
    .cart-sum {
        border-top: 1px solid $gray-border;
        margin: 7px 0 15px;
        padding: 7px 0 0;
        font-weight: bold;
    }
    .cart-item-highlight {
        background: $light-green;
        color: #fff;
        margin: 5px -15px 5px -30px;
        padding: 5px 15px 5px 30px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: -15px;
            left: 0;

            z-index: -1;

            display: block;
            border-top: 15px solid transparent;
            border-right: 15px solid $green;
        }
    }
}

.price-comparison {
    .comparison-item {
        font-weight: bold;
        .price {
            color: $brand-primary;
            display: block;
        }
    }
    .comparison-item-savings {
        font-weight: bold;
        background: $light-green;
        color: #fff;
        margin: -5px -32px 0 -15px;
        padding: 5px 32px 5px 15px;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -15px;
            right: 0;
            border-top: 15px solid transparent;
            border-left: 15px solid $green;
        }
    }
}

.product {
    h2 {
        margin-top: 0;
    }
    .name {
        font-size: 20px;
        color: $brand-primary;
    }
    .price {
        font-size: 32px;
        font-weight: bold;
        small {
            font-size: 14px;
        }
    }
    .panel-heading {
        color: $brand-primary;
    }
    .panel {
        .price {
            border-top: 1px solid $gray-border;
            margin-top: 20px;
            padding-top: 10px;
        }
    }
    .others {
        //&:extend(.clearfix all);
        margin: 0 0 30px -10px;
        overflow: hidden;
        overflow-x: auto;
        .scroll-wrapper {
            white-space: nowrap;
        }
        .item,
        .prev,
        .next {
            @include slide-thumbnail-mixin(84px, 64px);
            overflow: hidden;
            small {
                font-size: 10px;
            }
        }
        .item {
            padding: 4px;
            &:nth-of-type(2) {
                background-color: $brand-primary;
                color: white;
            }
            span {
                display: inline-block;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
            &.color {
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .prev,
        .next {
            @include slide-thumbnail-mixin(32px, 64px);
            @include slide-navigation-mixin();
            padding-top: 9px;
        }
        .active {
            border-color: $brand-primary;
        }
    }
}

.order-success {
    font-size: 20px;
    a {
        font-weight: bold;
    }
    h2 {
        color: $brand-primary;
        margin-bottom: 30px;
    }
    .step {
        font-size: 26px;
        text-align: center;
        .icon {
            width: 143px;
            height: 143px;
            border: 1px solid $gray-border;
            border-radius: 50%;
            display: inline-block;
            margin-bottom: 20px;
            color: $brand-primary;
            font-size: 70px;
            position: relative;
            padding-top: 20px;
            span {
                position: absolute;
                top: 3px;
                right: 3px;
                display: block;
                height: 34px;
                width: 34px;
                border-radius: 50%;
                background: $brand-primary;
                color: #fff;
                font-weight: bold;
                font-size: 20px;
                line-height: 32px;
            }
        }
    }
}

.option-panel {
    .panel-heading {
        background: none;
        padding: 20px 15px 20px 85px;
        position: relative;
        font-size: 20px;
        min-height: 70px;
        overflow: hidden;
        .accordion-toggle {
            &:before {
                content: "\f138";
                display: block;
                width: 70px;
                height: 70px;
                color: #fff;
                background: $brand-primary;
                text-align: center;
                font-size: 28px;
                position: absolute;
                top: 0;
                left: 0;
                padding: 15px 0 0;
            }
            &:after {
                font-family: $font-awesome;
                font-weight: 900;
                content: "\f102";
                padding: 0;
                float: right;
                color: $brand-primary;
            }
            &.collapsed {
                &:before {
                    content: "\f138";
                }
                &:after {
                    content: "\f103";
                }
            }
        }
    }

    .form-group {
        margin-top: 15px;
        margin-bottom: 0;
    }

    .radio,
    .checkbox {
        margin: 0 0 20px;

        @media (min-width: $screen-sm-min) {
            font-size: 20px;
        }

        input {
            top: -2px;
            left: 15px;

            @media (min-width: $screen-sm-min) {
                top: 4px;
                right: 0;
                left: auto;
            }
        }

        .price {
            display: block;
        }

        @media (min-width: $screen-sm-min) {
            i.fa {
                position: absolute;
                top: 4px;
                right: 140px;
            }

            .price {
                display: inline;
                position: absolute;
                right: 24px;
                top: 0px;
                font-weight: bold;
                text-align: right;

                small {
                    display: block;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 12px;
                }
            }

            label {
                padding-left: 0;
            }
        }
    }

    .checkbox .price {
        margin-left: 26px;

        @media (min-width: $screen-sm-min) {
            margin-left: 0;
        }
    }

    @media (min-width: $screen-sm-min) {
        .checkbox > label > span {
            position: absolute;
            top: 0;
            right: -10px;
        }
    }
}
/*.wrapper-rombe {
    height: 173px;
    position: relative;
    margin-top: 58px;
}*/

.login-page {
    .hero {
        h1 {
            font-size: 44px;
            font-weight: bolder;
        }
        p {
            font-size: 30px;
        }
    }
    p {
        &.lead {
            margin-bottom: 10px;
        }
    }
    .well {
        margin-top: 30px;
        padding: 36px;
    }
    .form-horizontal {
        .control-label {
            text-align: left;
        }
    }
}

.well {
    border: 0;
    border-radius: 0;
    @include box-shadow(inset, 0, 0, 0, transparent);
}

.region-teasers {
    border-bottom: 1px solid $lighter-grey;

    .icn-bubble {
        @media (min-width: $screen-sm-min) {
            float: left;
        }
    }

    .lead {
        line-height: 1.2;

        @media (min-width: $screen-sm-min) {
            float: left;
        }
    }
}

.btn-dark {
    @include button-variant($btn-dark-grey-color, $btn-dark-grey-bg, $btn-dark-grey-border);
}

li.list-group-item:first-child {
    border-radius: 0;
}

li.list-group-item {
    padding: 10px 5px;
}

.vendor-logo {
    float: right;
    margin-left: 30px;
    @media (max-width: $screen-xs-max) {
        display: none;
    }
}

.logo-row {
    height: 60px;
    padding-top: 4px;
    .welcome-message {
        float: left;
        margin: 0;
        line-height: 50px;
        @media (max-width: $screen-xs-max) {
            float: none;
            text-align: center;
            margin-top: 11px;
            position: relative;
            z-index: 10;
            span {
                display: none;
            }
        }
    }
    .nav > li > a {
        line-height: 30px;
    }
}

.text-icon-button {
    display: block;
    color: $gray-dark;
    text-align: center;

    @media (min-width: $screen-sm-min) {
        font-size: 20px;
        font-weight: bold;

        &:hover {
            color: $red;
            text-decoration: none;
        }
    }
}

.startsite_content,
.tariffs-tabs {
    .nav-tabs {
        li {
            a {
                @media (min-width: $screen-md-min) {
                    font-size: $font-size-large;
                }

                .icn {
                    stroke: $brand-primary;
                }
            }

            &.active {
                a {
                    .icn {
                        stroke: $text-color;
                    }
                }
            }
        }
    }
}

.table-showborder {
    tr {
        border-bottom: 1px solid $table-border-color;
        &:last-of-type {
            border-bottom: 0;
        }
    }
}

.nav.navbar-nav.checkout-progress > li > a {
    padding: 0;
    line-height: inherit;
    display: block;
    color: inherit;
    &:hover {
        background-color: transparent;
    }
}

// #6310
.vodafone-logo {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: -50px;
    bottom: 20px;
    &.absolute {
        top: 50px;
        left: 0px;
    }
    &.small {
        width: 50px;
        height: 50px;
    }
}

body.home-page {
    nav.navbar.navbar-main.navbar-default {
        margin-bottom: 0;
    }
    a.vodafone-logo {
        bottom: 0;
    }
}

.total-savings-title {
    font-weight: bold;
}

.font-green {
    color: $light-green;
}

.panel-dark.cart .panel-heading {
    color: $white;
}

.panel-dark {
    border: 1px solid $btn-dark-border;
}

// device colors and storage
.device-colors,
.device-storage {
    list-style: none;
    margin: 0;
    padding: 0;

    &-title {
        margin: 10px 0 5px 0;
    }

    &-item,
    &-item {
        display: inline-block;
        margin: 0 5px 0 0;
        padding: 0;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            color: black;
            border: 2px solid #575756;
            text-decoration: none;

            &.active {
                border-color: $brand-primary;
            }
        }
    }

    .device-colors-item a {
        span {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            bottom: -35px;
            background: #575756;
            color: #fff;
            padding: 4px 8px;
            transition: opacity 0.3s;
            z-index: 2;
        }

        &:hover span {
            opacity: 1;
            visibility: visible;
        }
    }
}

.warenkorb {
    .table > tbody > tr:first-child > td {
        border-top: none;
    }
}

// section
@import 'layout/modal';
@import 'layout/menu/index';
@import 'layout/_panel';
@import 'layout/_loader';
@import 'layout/_forms';

// pages
@import 'pages/Default/index';
@import 'pages/Device/detail';
@import 'pages/Device/overview';
@import 'pages/FAQ/index';
@import 'pages/Module/accordion';
@import 'pages/Module/tariffmobile';
@import 'pages/Order/newCustomer';
@import 'pages/Secure/login';
@import 'pages/Tariff/overview';
@import 'pages/Tariff/selectOptions';
@import 'pages/Tariff/selectTariff';
@import 'pages/Tariff/tariffInfo';
@import 'pages/Tariff/tariffTable';
@import 'pages/VVLLogin/vvllogin';
@import 'pages/Product/topDeals';
