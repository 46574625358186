#sidebar {
    &.affix-top {
        position: static;
    }

    &.affix {
        position: fixed;
    }

    &.affix-bottom {
        position: absolute;
    }
}
