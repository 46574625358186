
.modal {
    text-align: center;
    padding: 0 !important;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }

    .modal-vvl-aktion {
        .modal-content {
            background: #fff url('/bundles/rabeconceptshopweb/images/themes/vodafone/popups/aktions-popup.jpg') no-repeat;
            overflow: hidden;
            border: none;
            font-size: 16px;

            .logo {
                position: absolute;
                top: 0;
                right: 0;
            }

            h2 {
                color: $red;
                font-weight: bold;
            }

            .buttons {
                text-align: right;

                .btn {
                    margin-left: 20px;
                }
            }

            .close {
                position: absolute;
                top: 6px;
                right: 10px;
                color: #fff;
                opacity: 0.7;
                text-shadow: none;
                z-index: 5;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 40px 0;

                li {
                    position: relative;
                    padding: 10px 0 10px 40px;

                    &:before {
                        font-family: $font-awesome;
                        font-weight: 900;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        color: $red;
                        font-size: 30px;
                    }
                }
            }
        }
    }
}
