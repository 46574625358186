/** DEFAULTS
--------------------------------------------------------- */

$icn-font-default-size:     32px;
$icn-font-default-color:    #555555;

/** BREAKPOINTS
--------------------------------------------------------- */

$screen-sm-min: 768px;

/** SIZES
--------------------------------------------------------- */

$icn-font-sizes: (
    "xs": round($icn-font-default-size / 3), // 11px (if $default-size = 32px)
    "sm": round($icn-font-default-size / 2), // 16px (if $default-size = 32px)
    "md": round($icn-font-default-size),     // 32px (if $default-size = 32px)
    "lg": 48px,
    "xl": round($icn-font-default-size * 2)  // 64px (if $default-size = 32px)
);

/** COLORS
--------------------------------------------------------- */

$gray-100:  #f3f3f3; // @todo can be removed once bootstrap 4 is used
$white:     #ffffff; // @todo can be removed once bootstrap 4 is used
$black:     #000000; // @todo can be removed once bootstrap 4 is used
$primary:   #e60000; // @todo can be removed once bootstrap 4 is used

$theme-colors: (
    "white":   $white,
    "black":   $black,
    "primary": $primary
); // @todo can be removed once bootstrap 4 is used

$icn-font-default-bubble-size:                      100px;
$icn-font-default-bubble-icon-size:                 64px;
$icn-font-default-bubble-color-hover:               $white;
$icn-font-default-bubble-background-color:          $gray-100;
$icn-font-default-bubble-background-color-hover:    $primary;
