.navbar-nav .open .dropdown-menu {
    @media (max-width: $screen-sm-min) {
        border-radius: 0;
    }

    background-color: #fafafa;
}

.dropdown-content {
    .dropdown-menu {
        .rabe-dropdown {
            padding-bottom: 20px;

            p {
                font-size: 16px;
                padding-top: 10px;
            }

            ul {
                margin: 0;
                margin-top: -5px;
                padding: 0;

                li {
                    list-style-type: none;
                    padding: 0;
                    color: lighten($text-color, 30%);
                }
            }
            span {
                &.price {
                    display: block;
                    font-size: $font-size-m;
                    margin-top: 5px;

                    strong {
                        color: $brand-primary;

                        small {
                            display: none;
                        }
                    }
                }
            }

            .content {
                h3 {
                    margin-top: 15px;
                }
            }

            .device {
                margin-top: 20px;
                @include clearfix();

                &:nth-of-type(2n+1) {
                    clear: left;
                }

                p {
                    a {
                        color: $text-color;
                    }
                }
            }

            .tariff {
                background-color: $brand-primary;
                padding: 15px 20px;
                margin-top: 15px;
                display: block;
                text-align: center;
                color: white;
                font-size: $font-size-m;
                border-radius: $border-radius-base;
                font-weight: bold;
                max-height: 195px;
                overflow: hidden;

                &:hover {
                    text-decoration: none;
                    background-color: $gray;
                }

                span {
                    display: block;
                    font-size: $font-size-base;
                    font-weight: normal;
                }
            }
        }

        .dropdown-footer {
            background-color: lighten($lighter-grey, 10%);
            padding: 20px;
            text-align: center;
            margin-bottom: -10px;
            border-bottom-left-radius: $border-radius-base;
            border-bottom-right-radius: $border-radius-base;

            .dropdown-close {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

.navbar-main {
    .dropdown-menu {
        .rabe-dropdown {
            .dropdown-row {
                margin-top: 20px;

                @media (min-width: $screen-sm-min) {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }

                div:first-child {
                    display: none;

                    @media (min-width: $screen-md-min) {
                        display: block;
                        text-align: center;
                    }
                }

                a,
                a:hover {
                    color: $gray-dark;
                }

                .icn {
                    margin-top: -6px;
                }

                h3 {
                    font-size: 16px;
                    font-weight: bold;
                    margin: 0;
                }
                p {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}
