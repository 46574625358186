/** ALIGNMENTS
-------------------------------------------------------------------------------- */

.text {
    @media (min-width: $screen-xs-min) {
        &-xs {
            &-left      { text-align: left    !important }
            &-right     { text-align: right   !important }
            &-center    { text-align: center  !important }
            &-justify   { text-align: justify !important }
        }
    }
    @media (min-width: $screen-sm-min) {
        &-sm {
            &-left      { text-align: left    !important }
            &-right     { text-align: right   !important }
            &-center    { text-align: center  !important }
            &-justify   { text-align: justify !important }
        }
    }
    @media (min-width: $screen-md-min) {
        &-md {
            &-left      { text-align: left    !important }
            &-right     { text-align: right   !important }
            &-center    { text-align: center  !important }
            &-justify   { text-align: justify !important }
        }
    }
    @media (min-width: $screen-lg-min) {
        &-lg {
            &-left      { text-align: left    !important }
            &-right     { text-align: right   !important }
            &-center    { text-align: center  !important }
            &-justify   { text-align: justify !important }
        }
    }
}
