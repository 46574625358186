.tariff-overview {
    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: $font-size-large * 3;
        margin-top: -($font-size-large * 3);
        background: transparent;
        border: 0;
        color: $gray-light;
        &.slick-prev {
            left: -50px;
        }
        &.slick-next {
            right: -50px;
        }
    }
    .slick-dots {
        padding: 0;
        margin: 0;
        text-align: center;
        li {
            list-style-type: none;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
            button {
                border-radius: 50%;
                background-color: $gray-lighter;
                width: 17px;
                height: 17px;
                overflow: hidden;
                border: 0;
                text-indent: -1000px;
            }
            &.slick-active {
                button {
                    background-color: $brand-primary;
                }
            }
        }
    }
}

.ui-slider {
    margin-top: 17px;
}

.ui-widget-content {
    border: 0;
    background: #d9d9d9;
}

.ui-slider-horizontal {
    height: 8px;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    .ui-slider-handle {
        border-radius: 50%;
        top: -0.35em;
        &.ui-state-default {
            background-color: #FFF;
            border: 1px solid #FFF;
            @include box-shadow(0, 0, 2px, 2px, rgba(0, 0, 0, 0.2));
        }
        .ui-slider-horizontal .ui-slider-handle {
            top: -0.35em;
            margin-left: -0.6em;
        }
    }
}

.ui-widget-header {
    background-color: $green;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}


/*
#6530
*/

body {
    .slick-track {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        .slick-slide {
            .tariff {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                height: 100%;
                .panel-heading {
                    .tariff-name {
                        .vodafone-brand {
                            font-weight: 300;
                        }
                    }
                }
                .panel-body {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    .tariff-keyfacts {
                        flex: 1;
                    }
                }
            }
        }
    }
}
