/* tariff table */

.tariffOverview {
    font-size: 18px;
    color: #5a5a59;
}

.tariffOverview.table > tbody > tr > td,
.tariffOverview.table > tbody > tr > th {
    vertical-align: middle;
    height: 48px;
    border-color: #c6c6c6;
}

.tariffOverview td:nth-child(even),
.tariffOverview th:nth-child(even) {
    background: #e3e3e3;
}

.tariffOverview tr:last-child td {
    background: none;
}

.tariffOverview.table > tbody > tr.bg-grey td,
.tariffOverview.table > tbody > tr.bg-grey th {
    background: #c6c6c6;
    border-bottom: 5px solid #fff;
    border-top: 5px solid #fff;
}

.tariffOverview.table > tbody > tr.bg-grey td:nth-child(even),
.tariffOverview.table > tbody > tr.bg-grey th:nth-child(even) {
    background: #c6c6c6;
    border-bottom: 5px solid #e3e3e3;
    border-top: 5px solid #e3e3e3;
}

.tariffOverview tr:first-child th,
.tariffOverview tr:nth-child(2) td,
.tariffOverview tr:nth-child(2) th {
    border: none;
}

.tariffOverview tr:first-child th {
    color: #e60000;
    font-size: 26px;
    padding-bottom: 20px;
}

.tariffOverview td.device span {
    display: block;
    font-size: 15px;
}

.tariffOverview td.device.preferred {
    background: #e60000;
    color: #fff;
}

.tariffOverview .fa-check {
    color: green;
    font-size: 30px;
}

.tariffOverview .btn {
    font-weight: bold;
    font-size: 16px;
}

.tariffOverview .btn-muted {
    background: #c6c6c6;
    color: #6c6c6b;
}

.tariffOverview .btn-muted:hover,
.tariffOverview .btn-muted:focus {
    background: #b0b0b0;
}

/* device header */

.deviceHeader .name {
    color: #e60000;
    font-size: 20px;
    border-bottom: 2px solid #c6c6c6;
}

.deviceHeader ul.size,
.deviceHeader ul.color {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
}

.deviceHeader ul.size li + li,
.deviceHeader ul.color li + li {
    margin-left: 10px;
}

.deviceHeader ul.size li a,
.deviceHeader ul.color li a {
    width: 50px;
    height: 50px;
    border: 2px solid #575756;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    position: relative;
}

.deviceHeader ul.color li a span {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -35px;
    background: #575756;
    color: #fff;
    padding: 4px 8px;
    transition: opacity .3s;
}

.deviceHeader ul.color li a:hover span,
.deviceHeader ul.color li a:focus span {
    opacity: 1;
    visibility: visible;
}

.deviceHeader ul.size li a {
    background: #575756;
}

.deviceHeader ul.size li a.active {
    background: #e60000;
}

.deviceHeader ul.color li.current a {
    border-color: #e60000;
}

.deviceHeader .changeDevice {
    margin-top: 116px;
}